import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import XWUI from '@prettyy/ui'
import VXETable from 'vxe-table'
import XwPopoVer from '@core/components/xw-popover/XwPopoVer.vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import '@core/components-styles/ckEditorCustom.css'
import '@core/components-styles/vueSelectCustom.css'
import 'vxe-table/lib/style.css'
import '@prettyy/ui/dist/css/index.css'
import './styles/element.scss'

const BrowserLogger = require('alife-logger')

// eslint-disable-next-line no-underscore-dangle
const __bl = BrowserLogger.singleton(
  {
    pid: 'djjkfeapl4@c6e3096f38b0927',
    appType: 'web',
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
  },

)

VXETable.config({
  width: 150,
  loadingText: '加载中...',
  table: {
    tooltipConfig: {
      enterable: true,
    },
  },
})

Vue.config.productionTip = false
Vue.component('XwPopoVer', XwPopoVer)
Vue.use(XWUI)

Vue.use(VXETable)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
